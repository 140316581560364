export const ProductList = [
  {
    id: 1,
    name: "Dining Chairs",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-DiningChairs-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/dining_chair.jpg",
    thumbnailIconWebp: "/thumbnail/dining_chair.webp",
    altText: "Dining Chair",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 2,
    name: "Dining Tables",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-DiningTables-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/dining_table.jpg",
    thumbnailIconWebp: "/thumbnail/dining_table.webp",
    altText: "Dining Table",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 3,
    name: "Sofa",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Sofas-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/sofa.jpg",
    thumbnailIconWebp: "/thumbnail/sofa.webp",
    altText: "Sofa",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 4,
    name: "Coffee Tables",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-CoffeeTables-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/coffee_table.jpg",
    thumbnailIconWebp: "/thumbnail/coffee_table.webp",
    altText: "Coffee Table",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 5,
    name: "Arm Chairs",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Armchairs-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/arm_chair.jpg",
    thumbnailIconWebp: "/thumbnail/arm_chair.webp",
    altText: "Arm Chair",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 6,
    name: "Bedroom",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Bedroom-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/bed.jpg",
    thumbnailIconWebp: "/thumbnail/bed.webp",
    altText: "Bed",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 7,
    name: "Sectional Sofa",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Sectional-Sofas-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Sectional_Sofa.jpg",
    thumbnailIconWebp: "/thumbnail/Sectional_Sofa.webp",
    altText: "Sectional Sofa",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 8,
    name: "Nightstands",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Nightstands-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Nightstand.jpg",
    thumbnailIconWebp: "/thumbnail/Nightstand.webp",
    altText: "Nightstands",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 9,
    name: "Daybeds",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Chaises-Daybeds-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Daybed.jpg",
    thumbnailIconWebp: "/thumbnail/Daybed.webp",
    altText: "Daybeds",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 10,
    name: "Consoles",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Consoles_Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Console.jpg",
    thumbnailIconWebp: "/thumbnail/Console.webp",
    altText: "Consoles",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 11,
    name: "Benches",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Benches-Ottomans-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Bench.jpg",
    thumbnailIconWebp: "/thumbnail/Bench.webp",
    altText: "Benches",
    _target: "_blank",
    class: " mx-auto",
  },
  {
    id: 12,
    name: "Barstools",
    description: "",
    downloadLink:
      "https://nh48-catalogue.s3.ap-south-1.amazonaws.com/DEC-2023/NH48-Barstools-Dec_23.pdf",
    thumbnailIcon: "/thumbnail/Barstool.jpg",
    thumbnailIconWebp: "/thumbnail/Barstool.webp",
    altText: "Barstools",
    _target: "_blank",
    class: " mx-auto",
  },
];
