export const FaqQuestionList = [
  {
    idx: 1,
    question: `How good are the products?`,
    answer: `We make products that we would like to use, period.
    Every product we make goes through extensive planning for proportions, comfort, and durability. We use the highest quality materials, design tools, and manufacturing technologies to achieve the desired results.`,
  },
  {
    idx: 2,
    question: "Are the prices reasonable?",
    answer: `We commit ourselves to a fixed markup which does not move or change with regards to product or clients.
    We quote the same prices across the board.`,
  },
  {
    idx: 3,
    question: "What if I break a leg? (of furniture, of course!)",
    answer: `We offer an <span class="text-sm">18</span>-month warranty for manufacturing defects and non-accidental damages. Cost of repair/replacement is borne by us in this case.
    <p class="mt-2">If the damage is accidental, no worries either. 
    We provide hassle-free repair service/ solution at a minimal price. 
    The quotes are provided to you beforehand, and no hidden charges are there, ever.</p>`,
  },
];
