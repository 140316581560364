import { FC } from "react";
import { NH48 } from "../../utils/constants";
import { ExternalLinkSVGIcon } from "../../utils/utils";

const WA_LINK = `https://wa.me/${NH48.CONTACT.PHONE}?text=${NH48.WHATSAPP_TEXT}`;

const HowToOrder: FC = () => {
  return (
    <div
      className="pb-4 max-w-screen-xl mx-auto border-2 border-gray-100 mb-4"
      id="how_to_order"
    >
      <p className="text-center text-4xl font-bold text-gray-800 mt-8 mb-8 text-heading">
        How to order
      </p>
      <div className="gap-3 grid sm:mx-auto grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 sm:mt-8 px-5 md:px-10 lg:px-10 xl:px-10 2xl:px-10 mx-auto justify-items-center">
        {/* Step 1 */}
        <div className="max-w-md rounded-lg shadow-lg my-2 bg-white border mt-5">
          <div className="relative mb-6">
            <div
              className="text-center absolute w-full"
              style={{ bottom: "-30px" }}
            >
              <button className="p-4 rounded-full transition ease-in duration-200 focus:outline-none text-white bg-darkblue text-heading bg-nav-blue">
                <p className="w-6 h-6">1</p>
              </button>
            </div>
          </div>
          <div className="py-4 px-6 text-center tracking-wide">
            <p className="py-4 text-lg font-black">Choose Products</p>
            <ul className="text-left list-disc px-3">
              <li>
                Explore the catalogs at our website, or show us your
                designs.
              </li>

              <li className="mt-3">
                Tell us about your requirements.
                <ul className="pl-5 mt-3">
                  <li className="mt-1">
                    <a
                      href={WA_LINK}
                      data-action="share/whatsapp/share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/whatsapp.png"
                        alt="wa_icon"
                        className="h-4 inline-block mr-2"
                      />
                      {"+91 " + NH48.CONTACT.PHONE_TEXT} {ExternalLinkSVGIcon}
                    </a>
                  </li>
                  <li className="mt-1 uppercase text-sm">
                    <a
                      href={"mailto:" + NH48.CONTACT.EMAIL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/email.png"
                        alt="wa_icon"
                        className="h-3 inline-block mr-2"
                      />
                      {NH48.CONTACT.EMAIL}
                      {ExternalLinkSVGIcon}
                    </a>
                  </li>
                  <li className="mt-1 uppercase text-sm">
                    <a
                      href={NH48.CONTACT.SOCIAL.INSTAGRAM}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/icons/instagram.png"
                        alt="wa_icon"
                        className="h-4 inline-block mr-2"
                      />
                      {NH48.CONTACT.SOCIAL.INSTAGRAM_HANDLE}
                      {ExternalLinkSVGIcon}
                    </a>
                  </li>
                </ul>
              </li>
              <div className="mt-2"></div>
              <small className="italic text-gray-500 text-xs mt-2">
                Once you leave a query, our team will get back to you.
              </small>
            </ul>
          </div>
        </div>

        {/* Step 2 */}
        <div className="max-w-md rounded-lg shadow-lg my-2 bg-white border mt-5">
          <div className="relative mb-6">
            <div
              className="text-center absolute w-full"
              style={{ bottom: "-30px" }}
            >
              <button className="p-4 rounded-full transition ease-in duration-200 focus:outline-none text-white bg-darkblue text-heading bg-nav-blue">
                <p className="w-6 h-6">2</p>
              </button>
            </div>
          </div>
          <div className="py-4 px-6 text-center tracking-wide">
            <p className="py-4 text-lg font-black">Finalize Specifications</p>
            <ul className="text-left list-disc px-3">
              <li>
                {" "}
                We will discuss your choices of materials - for wood, metal,
                upholstery, finish, etc.
              </li>
              <li className="mt-3">
                A <span className="italic">BOQ</span>{" "}
                (Bill of Quantity) will be provided. 
              </li>
              <li className="mt-3">It will mention products, material specifications,
                and remarks that we'll have discussed.</li>
            </ul>
          </div>
        </div>

        {/* Step 3 */}
        <div className="max-w-md rounded-lg shadow-lg my-2 bg-white border mt-5">
          <div className="relative mb-6">
            <div
              className="text-center absolute w-full"
              style={{ bottom: "-30px" }}
            >
              <button className="p-4 rounded-full transition ease-in duration-200 focus:outline-none text-white bg-darkblue text-heading bg-nav-blue">
                <p className="w-6 h-6">3</p>
              </button>
            </div>
          </div>
          <div className="py-4 px-6 text-center tracking-wide">
            <p className="py-4 text-lg font-black">Place order</p>
            <ul className="text-left list-disc px-3">
              <li>
                You can place your order by making{" "}
                <span className="text-sm">50%</span> as the advance payment.
              </li>
              <li className="mt-3">
                The order will be considered placed only after the payment is
                made.
              </li>
              <li className="mt-3">
                Terms &amp; conditions will be mentioned in the BOQ.
              </li>
              <li className="mt-3">Usual lead time <span className="text-sm">60-65</span> business days.</li>
            </ul>
          </div>
        </div>

        {/* Step 4 */}
        <div className="max-w-md rounded-lg shadow-lg my-2 bg-white border mt-5">
          <div className="relative mb-6">
            <div
              className="text-center absolute w-full"
              style={{ bottom: "-30px" }}
            >
              <button className="p-4 rounded-full transition ease-in duration-200 focus:outline-none text-white bg-darkblue text-heading bg-nav-blue">
                <p className="w-6 h-6">4</p>
              </button>
            </div>
          </div>
          <div className="py-4 px-6 text-center tracking-wide">
            <p className="py-4 text-lg font-black">Delivery</p>
            <ul className="text-left list-disc px-3">
              <li>
                Once an order is ready, we share high-resolution images.
              </li>
              <li className="mt-3">
                You can then approve the same &amp; proceed with the remaining
                payment.
              </li>
              <li className="mt-3">
                No items are shipped without prior approval and full payment.
              </li>
              <li className="mt-3"> Shipping damages are covered by us.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToOrder;
