import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { FC, Fragment } from "react";
import useScrollPosition from "../../hooks/useScrollPosition";
import { NH48 } from "../../utils/constants";
import { navigation } from "../../utils/utils";
import { motion } from "framer-motion";

const SCROLL_BG_ADD_Y_COORDINATE = 50;

const HeroSection: FC<any> = ({ showWIP }) => {
  const { scrollClass } = useScrollPosition(
    SCROLL_BG_ADD_Y_COORDINATE,
    "backdrop-filter fix-navbar flex from-blue-200 items-center justify-between lg:justify-start md:px-8 relative sm:h-10 to-blue-450 xl:max-w-7xl",
    "transition ease-in-out bg-gradient-to-r fix-navbar flex items-center justify-between lg:justify-start md:px-8 relative sm:h-10 xl:max-w-7xl from-nav-blue backdrop-blur-lg backdrop-filter "
  );

  return (
    <div
      className="relative bg-white overflow-hidden max-w-screen-xl mx-auto"
      id={"home"}
    >
      <div className="">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 bg-dots ">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-0 sm:px-0">
              <nav
                className={scrollClass}
                aria-label="Global"
                data-toggle="dropdown"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#home">
                      <button>
                        <span className="sr-only">logo</span>
                        <picture>
                          <source
                            srcSet="logos/nh48-text-transparent.png"
                            type="image/webp"
                            className="rounded"
                          />
                          <img
                            loading="eager"
                            className="h-10 w-auto sm:h-10 border bg-white rounded"
                            alt="logo"
                            src="logos/nh48-text-transparent.png"
                            width="48px"
                            height="auto"
                          />
                        </picture>
                      </button>
                    </a>
                    <div className="-mr-2 flex items-center md:hidden px-3.5">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none bg-gray-500	no-tap-bg opacity-50 bg-sub-heading">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                  {navigation?.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={
                        item.hasOwnProperty("target") ? item.target : "_self"
                      }
                      className={
                        item?.classes +
                        " text-black hover:text-darkblue font-bold text-heading"
                      }
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute top-0 inset-x-0 transition transform origin-top-right md:hidden fix-popover"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-3 pt-3 flex items-center justify-between">
                    <div>
                      <picture>
                        <source
                          srcSet="./logos/nh48-text_.webp"
                          type="image/png"
                          className="rounded"
                        />
                        <img
                          className="h-10 w-auto sm:h-10 border rounded"
                          src="./logos/nh48-text_.webp"
                          alt="logo"
                          width="2.8rem"
                          height="2.8rem"
                        />
                      </picture>
                    </div>
                    <div className="-mr-2 px-3.5">
                      <Popover.Button className="rounded p-2 inline-flex items-center justify-center  text-darkblue  focus:outline-none bg-white no-tap-bg text-heading">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation?.map((item, idx) => (
                      <a
                        href={item.href}
                        key={idx}
                        target={
                          item.hasOwnProperty("target") ? item.target : "_self"
                        }
                        className={item?.classes}
                      >
                        <Popover.Button
                          className="block px-3 py-2 rounded-md text-base font-medium text-heading hover:bg-gray-50 w-full text-left flex items-center"
                          key={item.name}
                        >
                          {item.icon}
                          {item.name}
                        </Popover.Button>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-12 mx-auto max-w-7xl px-4 sm:mt-14 sm:px-8 md:mt-18 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center lg:text-left 2xl:text-left xl:text-left md:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-black sm:text-5xl md:text-6xl">
                <motion.div
                  className="block lg:w-1.5 text-center xl:inline pfd_heading_fonts font-light mb-4 text-black"
                  style={{ display: "block" }}
                  initial={{ scale: 0 }}
                  animate={{ rotate: 0, scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 160,
                    damping: 20,
                    delay: 0.2,
                  }}
                >
                  {NH48.HERO_HEADING_1}
                </motion.div>

                <motion.div
                  className="block  xl:inline font-light mt-4 text-lightblue"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ ease: "easeOut", delay: 0.5 }}
                >
                  {NH48.HERO_HEADING_2}
                </motion.div>
              </h1>
              <motion.p
                className="mt-4 text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0 text-lightpink"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeOut", delay: 0.6 }}
              >
                {NH48.HERO_SUBHEADING_1} <br /> {NH48.HERO_SUBHEADING_1_1}
              </motion.p>
              <div className="mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#downloads"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white text-lightblue hover:text-darkblue md:py-4 md:text-lg md:px-10 shadow-lg"
                  >
                    Download Catalogues
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <picture>
          <source srcSet={NH48.HERO_IMAGE_WEBP} type="image/webp" />
          <img
            loading="eager"
            className="h-56 object-cover sm:h-72 md:h-96 lg:h-full sm:w-full w-full"
            src={NH48.HERO_IMAGE}
            alt="hero_image"
            height="200px"
            width="auto"
          />
        </picture>
      </div>
    </div>
  );
};

export default HeroSection;
