import { FC } from "react";
import { divId, ProductSVGIcon } from "../../utils/utils";
import { ProductList } from "./constants";

const Products: FC<any> = () => {
  return (
    <div
      className="flex flex-wrap items-center justify-center mt-20 max-w-screen-xl mx-auto border-2 border-gray-100 mb-4 product-wrapper"
      id={divId.downloads}
    >
      <p className="text-center text-4xl font-bold mt-8 w-full mb-8 text-heading">
      Catalogues
      </p>
      {ProductList?.map((product) => {
        return (
          <a
            href={product.downloadLink}
            className="product__card-container"
            target={product._target}
          >
            <div
              className="lg:w-2/12 sm:w-full md:w-3/12 rounded m-1 border-2 hover:shadow-xl grayscale-card product-card"
              key={product.id}
            >
              <div>
                <picture>
                  <source
                    srcSet={product.thumbnailIconWebp}
                    type="image/webp"
                  />
                  <img
                    loading="lazy"
                    className={product.class + " rounded-b-2xl border-b-2"}
                    src={product.thumbnailIcon}
                    alt={product.altText}
                    height="240px"
                    width="240px"
                  />
                </picture>
              </div>
              <div className="px-6 py-4 h-28 sm:h-20 product-name">
                <div className="text-xl mb-2 product-name-text">
                  {product.name}
                </div>
              </div>
              <a
                href={product.downloadLink}
                className={
                  " w-full text-lightblue text-xs text-gray-500 py-3 px-4 rounded inline-flex items-center bg-nav-blue"
                }
                target={product._target}
              >
                {ProductSVGIcon}
                <span>Download</span>
              </a>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Products;
