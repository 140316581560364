import { FC } from "react";
import { CheckSVGIconFilled } from "../../utils/utils";

const Statements = {
  question: "We do what we do.",
  answer: [
    "No outsourcing",
    "Fair wages for workers",
    "Made in India",
    "Cruelty-free materials",
    "Transparent pricing",
    "Sustainable processes",
  ],
};

const EthosStatements: FC = () => {
  return (
    <div className="bg-white max-w-screen-xl w-full  border-2 border-gray-100 mb-4 mx-auto p-4">
      <h1 className="w-full text-center text-4xl font-bold text-gray-800 mt-8 mb-8 text-heading">
        {Statements?.question}
      </h1>
      <ul
        className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-4 mt-5 gap-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10"
        id="ethos"
        style={{ margin: "0 auto" }}

      >
        {Statements?.answer?.map((dialogue, idx) => {
          return (
            <li
              className="mt-2 font-light text-justify leading-4 border p-4 rounded-2xl shadow-md hover:bg-gray-200"
              key={idx}
            >
              <div className="float-left text-lightblue">{CheckSVGIconFilled}</div>
              <div className="leading-6">{dialogue}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EthosStatements;
