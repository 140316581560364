import { FC } from "react";

const Footer: FC = () => {
  return (
    <footer
      id="footer"
      className="max-w-screen-xl mx-auto px-5 text-white bg-lightblue"
    >
      <div
        className="pt-4 pb-4 text-center text-white text-xs uppercase"
        style={{ textAlign: "right" }}
      >
        &copy; {new Date().getFullYear()} NH48. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
