import { FC } from "react";
import { ContactList } from "./constants";

const ContactCard: FC = () => {
  return (
    <div className="container">
      <address className="not-italic text-light">
        {ContactList?.map((contact) => {
          return (
            <div id={contact.divId} key={contact.idx} className={contact.cssClasses}>
              <a href={contact.hrefLink} target="_blank" rel="noreferrer">
                <p className="text-md text-gray-500 font-light">
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <span>{contact?.SVGIcon}</span>
                    <div
                      dangerouslySetInnerHTML={{ __html: contact.hrefText }}
                      className="text-darkblue"
                    ></div>
                  </div>
                </p>
              </a>
            </div>
          );
        })}
      </address>
      {/* <div
        id="newsletter"
        className="lg:mt-32 xl:mt-32 2xl:mt-32 md:mt-28 sm:mt-24 mt-10"
      >
        <SignUpForm />
      </div> */}
    </div>
  );
};

export default ContactCard;
