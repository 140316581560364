import { FC } from "react";
import useScrollPosition from "../../hooks/useScrollPosition";

const BACK_TO_TOP_START_Y_DIMENSION = 460;

const BackToTop: FC = () => {
  const { scrollClass } = useScrollPosition(BACK_TO_TOP_START_Y_DIMENSION, "back-to-top", "back-to-top show-back-to-top");

  const BackToTop = (e: any) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <div>
      <h1
        className={
          scrollClass +
          " flex to-gray-700 from-gray-500 text-white w-auto h-12 p-3 hover:shadow-2xl bg-sub-heading"
        }
        onClick={BackToTop}
      >
        <svg
          xmlns="https://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
      </h1>
    </div>
  );
};

export default BackToTop;
