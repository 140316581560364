import { FC } from "react";
import { divId } from "../../utils/utils";
import ContactCard from "../contactCard";
import Gmap from "../googleMap";

const Contact: FC<any> = () => {
  return (
    <div
      className="max-w-screen-xl bg-white dark:bg-gray-800 mx-auto px-4 sm:px-6 lg:px-8 relative py-26 lg:mt-4 mt-11 border-2 border-gray-100 mb-4"
      id={divId.contact}
    >
      <p className="text-center text-4xl font-bold text-gray-800 mb-8 mt-8 text-heading">
        Contact us
      </p>
      <div className="relative mb-10">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-4 mt-16">
          <div className="lg:col-start-2 lg:max-w-2xl  pl-5 mb-5 w-2/3 border-l-2 border-gray-600">
            <ContactCard />
          </div>
          <div
            className="mt-10 lg:-mx-4 relative relative-20 lg:mt-0 lg:col-start-1  rounded-sm"
            id="contact_us"
          >
            <div
              className="relative space-y-4 flex justify-center"
            >
              <Gmap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
