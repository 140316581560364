import { useEffect, useState } from "react";

const useScrollPosition = (
  thresholdScrollValue: number,
  oldClass: string,
  newClass: string
) => {
  const [scrollClass, setScrollClass] = useState(oldClass);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setScrollClass(
        currentScrollY < thresholdScrollValue ? oldClass : newClass
      );
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [thresholdScrollValue, oldClass, newClass]);
  return { scrollClass };
};

export default useScrollPosition;
