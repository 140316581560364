import { NH48 } from "../../utils/constants";
import {
  PhoneSVGIcon,
  EmailSVGIcon,
  LocationMarkerIcon,
  InstagramSVGIcon,
  ContactHeadingIcon,
  ClockSVGIcon,
} from "../../utils/utils";

export const ContactList = [
  {
    idx: 0,
    SVGIcon: ContactHeadingIcon,
    title: "Heading",
    hrefLink: "",
    hrefText: NH48.CONTACT.TITLE,
    divId: "heading",
    cssClasses: "mt-1",
  },
  {
    idx: 122,
    SVGIcon: LocationMarkerIcon,
    title: "Location Showroom",
    hrefLink: NH48.CONTACT.G_MAP_HREF_SHOWROOM,
    hrefText: NH48.CONTACT.LOCATION_SHOWROOM,
    divId: "location studio",
    cssClasses: "mt-5",
  },
  {
    idx: 1,
    SVGIcon: LocationMarkerIcon,
    title: "Location Workshop",
    hrefLink: NH48.CONTACT.G_MAP_HREF_WORKSHOP,
    hrefText: NH48.CONTACT.LOCATION_WORKSHOP,
    divId: "location workshop",
    cssClasses: "mt-5",
  },
  {
    idx: 11,
    SVGIcon: LocationMarkerIcon,
    title: "Location Studio",
    hrefLink: NH48.CONTACT.G_MAP_HREF_STUDIO,
    hrefText: NH48.CONTACT.LOCATION_STUDIO,
    divId: "location studio",
    cssClasses: "mt-5",
  },
  {
    idx: 88,
    SVGIcon: ClockSVGIcon,
    title: "Timing",
    hrefLink: "",
    hrefText: `<p class="uppercase text-sm pl-1"><span> Mon - Fri</span> <br /><time>10:00 AM</time> - <time>06:00 PM</time></p>`,
    divId: "email",
    cssClasses: "mt-5",
  },
  {
    idx: 2,
    SVGIcon: PhoneSVGIcon,
    title: "Phone",
    hrefLink: "tel:" + NH48.CONTACT.PHONE,
    hrefText: "+91 " + NH48.CONTACT.PHONE_TEXT,
    divId: "phone",
    cssClasses: "mt-5",
  },
  {
    idx: 3,
    SVGIcon: EmailSVGIcon,
    title: "Email",
    hrefLink: "mailto:" + NH48.CONTACT.EMAIL,
    hrefText: NH48.CONTACT.EMAIL_TEXT,
    divId: "email",
    cssClasses: "mt-1",
  },

  {
    idx: 4,
    SVGIcon: InstagramSVGIcon,
    title: "Social",
    hrefLink: NH48.CONTACT.SOCIAL.INSTAGRAM,
    hrefText: NH48.CONTACT.SOCIAL.INSTAGRAM_TEXT,
    divId: "social",
    cssClasses: "mt-1",
  },
];
