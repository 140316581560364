import { useEffect, useState } from "react";
import About from "./components/about";
import BackToTop from "./components/backToTop";
import Contact from "./components/contact";
import EthosStatements from "./components/ethos";
import Faq from "./components/faq";
import Footer from "./components/footer";
import HowToOrder from "./components/howToOrder";
import HeroSection from "./components/main";
import Products from "./components/product";
import WIP from "./components/wip";

function App() {
  const [showWIP, setShowWIP] = useState(false);

  useEffect(() => {
    const wip = window?.location?.search?.includes("wip");
    setShowWIP(wip);
  }, []);

  const handleWIP = (state: boolean) => {
    console.log("WIP Trigger");
    setShowWIP((pre) => !pre);
  };
  return (
    <div className="app">
      {!showWIP ? (
        <div>
          <HeroSection showWIP={handleWIP} />
          <Products />
          <About />
          <Faq />
          <HowToOrder />
          <EthosStatements />
          <Contact />
          <Footer />
          <BackToTop />
        </div>
      ) : (
        <WIP showWIP={handleWIP} />
      )}
    </div>
  );
}

export default App;
