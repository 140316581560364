import { Popover } from "@headlessui/react";
import useScrollPosition from "../../hooks/useScrollPosition";
import { NH48 } from "../../utils/constants";
import { BackSVGIcon } from "../../utils/utils";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const SCROLL_BG_ADD_Y_COORDINATE = 50;
type props = {
  showWIP: Function;
};
const WIP = ({ showWIP }: props) => {
  const [isWix, setIsWix] = useState(false);

  useEffect(() => setIsWix(window?.location?.search?.includes("wix")), []);

  const { scrollClass } = useScrollPosition(
    SCROLL_BG_ADD_Y_COORDINATE,
    "backdrop-filter fix-navbar flex from-blue-200 items-center justify-between lg:justify-start md:px-8 relative sm:h-10 to-blue-450 xl:max-w-7xl",
    "transition ease-in-out bg-gradient-to-r fix-navbar flex items-center justify-between lg:justify-start md:px-8 relative sm:h-10 xl:max-w-7xl from-gray-200 backdrop-blur-lg backdrop-filter"
  );

  return (
    <div
      className="relative bg-white overflow-hidden max-w-screen-xl mx-auto"
      id={"home"}
    >
      <div className="">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 bg-dots ">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-0 sm:px-0">
              <nav
                className={scrollClass}
                aria-label="Global"
                data-toggle="dropdown"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#home">
                      <button>
                        <span className="sr-only">logo</span>
                        <picture>
                          <source
                            srcSet="logos/nh48-text-transparent.webp"
                            type="image/webp"
                            className="rounded"
                          />
                          <img
                            loading="eager"
                            className="h-10 w-auto sm:h-10 border bg-white rounded"
                            alt="logo"
                            src="logos/nh48-text-transparent.png"
                            width="48px"
                            height="auto"
                          />
                        </picture>
                      </button>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </Popover>

          <main className="mt-12 mx-auto max-w-7xl px-4 sm:mt-14 sm:px-8 md:mt-18 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center lg:text-left 2xl:text-left xl:text-left md:text-left flex flex-col gap-3">
              <h1 className="text-4xl tracking-tight font-extrabold text-black sm:text-5xl md:text-6xl">
                <motion.div
                  className="block lg:w-1.5 text-center xl:inline pfd_heading_fonts font-light mb-4 text-heading"
                  style={{ display: "block" }}
                  initial={{ scale: 0 }}
                  animate={{ rotate: 0, scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 160,
                    damping: 20,
                    delay: 0.2,
                  }}
                >
                  🚧
                  <br />
                  <br />
                  {NH48.HERO_HEADING_1}
                </motion.div>

                <motion.div
                  className="block  xl:inline font-light mt-4 text-lightblue"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ ease: "easeOut", delay: 0.5 }}
                >
                  Work in progress.
                </motion.div>
              </h1>

              <div
                className="mt-8 sm:flex sm:justify-center lg:justify-start"
                style={{ marginTop: "3rem", cursor: "pointer" }}
                onClick={() =>
                  !isWix
                    ? window.close()
                    : window.location.href = 'https://www.nh48.in'
                }
              >
                <div className="rounded-md shadow">
                  {/* @ts-ignore */}
                  <a
                    href={"#top"}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white text-lightblue hover:text-darkblue md:py-4 md:text-lg md:px-10 shadow-lg"
                  >
                    <span>{BackSVGIcon}</span> {isWix ? 'Homepage' : 'Go Back'}
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <picture>
          <source srcSet={NH48.HERO_IMAGE_WEBP} type="image/webp" />
          <img
            loading="eager"
            className="object-cover sm:h-72 md:h-96 lg:h-full sm:w-full w-full"
            src={NH48.HERO_IMAGE}
            alt="hero_image"
            height="200px"
            width="auto"
          />
        </picture>
      </div>
    </div>
  );
};

export default WIP;
