export const AboutUsList = [
  {
    answer: `We're a young, enthusiastic design practice -- building a home décor brand that is aspirational, sustainable & artistic.`,
    cssClasses: "mb-3",
  },
  {
    answer: [
      `At <span class="italic">NH48</span>, we design + produce furniture pieces for residential and commercial usage. Our products are custom-made to the requirements. We focus on ensuring a definitive and swift furniture buying experience.`,
      `We believe that good furniture should have high quality input materials, meticulous craftsmanship and attention to detail. Everything we do is focused on these tenets -- to achieve an elevated user experience in interaction with the space.`,
      `Our usual lead time is <span class="text-sm">60-65</span> business days.`,
    ],
    cssClasses: "",
  },
];
