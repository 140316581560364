import { FC } from "react";
import { divId } from "../../utils/utils";
import { AboutUsList } from "./constants";

const About: FC<any> = () => {
  return (
    <div
      className="max-w-screen-xl mx-auto bg-white w-full pb-10 border-2 border-gray-100 mb-4 padding-fix"
      id={divId.about}
    >
      <p className="text-center text-4xl font-bold text-gray-800 mt-8 mb-8 text-heading">
        About us
      </p>
      <p className="text-center text-xl font-normal text-gray-500 mb-6 lg:mb-2 xl:mb-2 md:mb-2 2xl:mb-2">
        {/* Meat the best team in world */}
      </p>
      <div className="flex justify-center">
        <div className="w-1/3" id="about_bg">
          <picture>
            <source srcSet="/about_us.webp" type="image/webp" />
            <img
              alt="about_us"
              data-src="/about_us.jpg"
              className="lazyload w-2/3"
            />
          </picture>
        </div>
        <div className="w-50 text-justify place-self-start">
          {AboutUsList?.map((about, idx) => {
            return (
              <div className={about.cssClasses} key={idx}>
                {typeof about.answer === "object" ? (
                  about?.answer?.map((points, idx) => {
                    return (
                      <p
                        className="mt-3 leading-7"
                        key={idx}
                        dangerouslySetInnerHTML={{ __html: points }}
                      ></p>
                    );
                  })
                ) : (
                  <p className="mt-3 leading-7">{about.answer}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
