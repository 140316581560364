import React, { FC } from "react";
import { FaqQuestionList } from "./constants";

const Faq: FC<any> = ({ hashUpdated }) => {
  return (
    <>
      <div
        className="max-w-screen-xl mx-auto bg-white w-full border-2 border-gray-100 mb-4"
      >
        <p className="text-center text-4xl font-bold text-gray-800 mt-8 mb-8 text-heading">
          FAQs
        </p>
        <div className="flex flex-col md:flex-row mx-auto p-4">
          <div className="md:w-1/3 text-3xl w-full mr-20" id="faq_bg">
            <picture>
              <source srcSet="/faq_bg.png" type="image/webp" />
              <img alt="faq" data-src="/faq_bg.jpg" className="lazyload"/>
            </picture>
          </div>
          <dl className="w-full md:w-2/3 place-self-center w-50  margin-fix ml-6">
            {FaqQuestionList?.map((faq) => {
              return (
                <React.Fragment key={faq.idx}>
                  <dt className="mb-3">
                    <h3 className="font-semibold">{faq.question}</h3>
                  </dt>
                  <dd
                    className="mb-10 text-justify leading-7"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  ></dd>
                </React.Fragment>
              );
            })}
          </dl>
        </div>
      </div>
    </>
  );
};

export default Faq;
