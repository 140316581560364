import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { NH48 } from "../../utils/constants";
import { useCallback, useState } from "react";

const Gmap = () => {
  const [map_, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: NH48.CONTACT.G_MAP_API_KEY,
  });

  const center = {
    lat: NH48.CONTACT.GEO_LOCATION.LATITUDE,
    lng: NH48.CONTACT.GEO_LOCATION.LONGITUDE,
  };

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    console.log(map_);
    setMap(map);
  }, [map_]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const containerStyle = {
    height: "540px",
    width: "75%",
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        onClick={() => window.open(NH48.CONTACT.G_MAP_HREF_SHOWROOM)}
        position={{
          lat: NH48.CONTACT.GEO_LOCATION.LATITUDE,
          lng: NH48.CONTACT.GEO_LOCATION.LONGITUDE,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};
export default Gmap;
