export const NH48 = {
  CONTACT: {
    SOCIAL: {
      INSTAGRAM: "https://www.instagram.com/nh48_in/?hl=en",
      INSTAGRAM_TEXT: `<span class="text-sm">@NH48_IN<span>`,
      INSTAGRAM_HANDLE: "@nh48_in",
    },
    GEO_LOCATION: {
      LATITUDE: 22.351181131945864,
      LONGITUDE: 73.13141423991408,
      ZOOM: 15,
    },
    PHONE_TEXT: "82646 59793",
    PHONE: "+918264659793",
    EMAIL_TEXT: `<span class="text-sm tracking-wide pl-fix">CONNECT@NH48.IN<span>`,
    EMAIL: "Connect@nh48.in",
    TITLE: '<span class="font-bold">NH48 Furniture & Home Dècor</span>',
    LOCATION_WORKSHOP: `<span class="italic">Workshop</span> <br /> Opp. Amrutam Hospital,<br /> Undera, Vadodara - 391310`,
    LOCATION_SHOWROOM: `<span class="italic">Showroom</span> <br /> MLA & Sons Farm, <br /> Opp. Westgate Elite,<br />Undera-Koyli Rd,<br /> Undera, Vadodara - 391310`,
    LOCATION_STUDIO: `<span class="italic">Studio</span> <br />2F, Shardul House, <br />4/A Aatmajyoti Ashram Rd,<br />Subhanpura, Vadodara - 390023`,
    G_MAP_HREF_WORKSHOP: `https://www.google.com/maps/place/NH48/@22.351174,73.1312808,21z/data=!4m6!3m5!1s0x395fc9a8823ea5c9:0x12ae227f6bf612b0!8m2!3d22.3511917!4d73.1314145!16s%2Fg%2F11c532tzx_?entry=ttu`,
    G_MAP_HREF_STUDIO: `https://www.google.com/maps/place/NH48+Furniture+%26+Home+D%C3%A8cor,+opp.+Amrutam+Hospital,+Undera,+Vadodara,+Gujarat+391310/@22.3177599,73.1502488,17z/data=!4m14!1m7!3m6!1s0x395fc94f88e29145:0x2d0e217b22e98c60!2sNH48+Furniture+%26+Home+D%C3%A8cor,+opp.+Amrutam+Hospital,+Undera,+Vadodara,+Gujarat+391310!8m2!3d22.317755!4d73.1528237!16s%2Fg%2F11v5b3g4ww!3m5!1s0x395fc94f88e29145:0x2d0e217b22e98c60!8m2!3d22.317755!4d73.1528237!16s%2Fg%2F11v5b3g4ww?entry=ttu`,
    G_MAP_HREF_SHOWROOM: `https://maps.app.goo.gl/B8aWhRppwNvqd2A49`,
    G_MAP_API_KEY: "AIzaSyDGkyTYBQyEnBgecOfs2XzEfl5iX9dgOrc",
  },
  HERO_IMAGE: "/hero-image-1.jpg",
  HERO_IMAGE_WEBP: "/hero-image-1.webp",
  HERO_HEADING_1: "NH48.",
  HERO_HEADING_2: "Your new destination for all things home décor.",
  HERO_SUBHEADING_1: `Approachable people. Timeless designs.`,
  HERO_SUBHEADING_1_1: `Meticulous crafting.`,
  WHATSAPP_TEXT: encodeURI(
    "Hello team NH48, \nI would like to tell my requirements."
  ),
};
